import React, { useEffect, useRef, useState } from 'react';
import Layout from './components/Layout';
import * as styles from '../styles/about.module.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion } from 'framer-motion';

import Crew from '../utils/crewDummy.json';
import Testimonial from '../utils/testimonyDummy.json';
import Carousel from 'react-elastic-carousel';

export default function About() {
  const [off, setOff] = useState(0);
  const handleScroll = () => setOff(window.pageYOffset);

  const [width, setWidth] = useState(0);
  const carousel = useRef(null);

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - 990);
    console.log(carousel.current.scrollWidth - 590);
  }, []);

  const tl = gsap.timeline();
  gsap.registerPlugin(ScrollTrigger);

  let aboutBanner = useRef(null);
  let aboutBannerHeader = useRef(null);
  let aboutBannerText = useRef(null);
  let aboutBannerQuote = useRef(null);
  let aboutBannerImageOverlay = useRef(null);
  let aboutSection = useRef(null);

  // useEffect(() => {
  //   tl.from(aboutBannerHeader, 1, {
  //     x: -100,
  //     opacity: 0,
  //     ease: 'Power3.easeOut',
  //   })

  //     .from(aboutBannerText, 1, {
  //       x: -100,
  //       opacity: 0,
  //       ease: 'Power3.easeOut',
  //     })

  //     .to(aboutBannerImageOverlay, 1, {
  //       height: 0,
  //       ease: 'expo.Out',
  //     })

  //     .from(aboutBannerQuote, 1, {
  //       y: 100,
  //       opacity: 0,
  //       ease: 'power3.easeOut',
  //       scrollTrigger: {
  //         trigger: aboutBanner,
  //         start: '0%',
  //         end: '100%',
  //         pinSpacing: false,
  //         scrub: true,
  //       },
  //     })

  //     .from(aboutSection, 1, {
  //       y: 100,
  //       opacity: 0,
  //       ease: 'power3.easeOut',
  //       delay: 0.3,
  //       stagger: {
  //         amount: 0.4,
  //       },
  //       scrollTrigger: {
  //         trigger: aboutBanner,
  //         start: '50%',
  //         end: '100%',
  //         pinSpacing: false,
  //         scrub: true,
  //       },
  //     })
  // }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout>
      <div className={styles.about_wrapper}>
        <div
          className={styles.about_banner}
          ref={(el) => {
            aboutBanner = el;
          }}>
          <div className={styles.about_banner_content_wrapper}>
            <div className={styles.about_banner_content_inner_wrapper}>
              <div className={styles.about_banner_content_inner}>
                <h3 ref={(el) => (aboutBannerHeader = el)}>Our mission</h3>
                <p ref={(el) => (aboutBannerText = el)}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  non ornare massa ut lectus eget nunc amet euismod. Consequat
                  vitae tortor in vel. Sed rhoncus diam amet turpis quam ac nisl
                  at. Vel magna amet, at egestas arcu.Nam et nunc neque feugiat
                  nullam elit.
                </p>
              </div>
              <div className={styles.bannerImg_wrapper}>
                <img src='/aboutbanner.png' alt='' />
                <div
                  className={styles.bannerImg_overLay}
                  ref={(el) => (aboutBannerImageOverlay = el)}></div>
              </div>
            </div>
          </div>
          <div
            className={styles.about_quote_wrapper}
            ref={(el) => (aboutBannerQuote = el)}>
            <div className={styles.about_quote_inner}>
              <h4>
                “The future belongs to those who believe in the beauty of their
                dreams”
              </h4>
              <h6>Eleanor Roosevelt</h6>
            </div>
          </div>
        </div>
        <div
          className={styles.about_section_wrapper}
          ref={(el) => (aboutSection = el)}>
          <div className={styles.about_section_inner}>
            <div className={styles.about_section_inner_img}>
              <img
                src='/aboutbanner.png'
                alt=''
                className={styles.about_img1}
              />
              <img src='/abt1.png' alt='' className={styles.about_img2} />
              <img src='/abt2.png' alt='' className={styles.about_img3} />
            </div>
            <div className={styles.about_section_content_text}>
              <h3>How the journey started</h3>
              <p>
                The product and company known as Rise today began with an idea
                that sparked in 2014. We realized that due to currency shocks,
                high inflation rates and the impact of devaluation, most
                investment opportunities across emerging markets do not yield
                enough returns for investors to grow their wealth. This makes it
                difficult for people in these markets to achieve their financial
                goals.
                <br />
                <br />
                To solve this problem, we built Rise as a simple way of
                connecting our users to dollar-denominated investments in
                developed markets, managed by a world class team using smart
                algorithms to guide our decisions, while helping our users
                become more financially literate.
              </p>
              <button className={styles.about_section_button}>join us</button>
            </div>
          </div>
        </div>
        <div className={styles.about_content_section2_wrapper}>
          <div className={styles.about_content_section2_inner}>
            <div className={styles.about_content_section2_inner_wrapper}>
              <div className={styles.about_section_flex1}>
                <h3>Our Team</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu
                  vulputate eget enim ipsum. Euismod nec vitae varius pharetra.
                  Netus ultricies maecenas posuere praesent vulputate leo
                  rhoncus. Arcu iaculis pretium quisque pretium risus, euismod
                  vitae venenatis. Ipsum et aenean amet, vestibulum in netus mi,
                  justo, risus.
                </p>
              </div>
              <div className={styles.about_section_flex2}>
                <h3>Our Values</h3>
                <div className={styles.inner_flex}>
                  <img src='/dollar.png' alt='' />
                  <p>Efficiency first</p>
                </div>
                <div className={styles.inner_flex}>
                  <img src='/clock.png' alt='' />
                  <p>Remote team</p>
                </div>
                <div className={styles.inner_flex}>
                  <img src='/tool.png' alt='' />
                  <p>Support driven</p>
                </div>
                <div className={styles.inner_flex}>
                  <img src='/tool.png' alt='' />
                  <p>A focus on craft</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.about_image_wrapper}>
            <div className={styles.earth}>
              <img src='/earth.png' alt='' />
            </div>
            <div className={styles.about_image_text}>
              <h3>Humans Helping Humans</h3>
              <p>A team that is as wide as it is diverse</p>
            </div>
          </div>
          <div className={styles.bottomflex_wrapper}>
            <div className={styles.bottomflex_inner}>
              <div>
                <h3>6</h3>
                <p>Countries</p>
              </div>
              <div>
                <h3>6</h3>
                <p>Teammates</p>
              </div>
              <div>
                <h3>6</h3>
                <p>Languages</p>
              </div>
            </div>
          </div>
          <div className={styles.about_offer_wrapper}>
            <h3>What We Offer</h3>
            <div className={styles.about_offer_content}>
              <div className={styles.about_offer_content_inner}>
                <img src='/dollar.png' alt='' />
                <h4>Profit-sharing</h4>
                <p>
                  We believe in giving back to our team, that’s why we give back
                  profits to our team
                </p>
              </div>

              <div className={styles.about_offer_content_inner}>
                <img src='/dollar.png' alt='' />
                <h4>Health-insurance</h4>
                <p>
                  We’ll cover your health insurance premiums across different
                  countries you’re in.
                </p>
              </div>

              <div className={styles.about_offer_content_inner}>
                <img src='/dollar.png' alt='' />
                <h4>Remote set-up</h4>
                <p>
                  Just getting started at home? We’ll sort out your work from
                  home set-up.
                </p>
              </div>

              <div className={styles.about_offer_content_inner}>
                <img src='/dollar.png' alt='' />
                <h4>Education stipend</h4>
                <p>
                  Want to buy a course? We’ve got you. Anything you need to help
                  progress, it’s on us.
                </p>
              </div>
            </div>
            
            <div className={styles.about_offer_content2}>
              <div className={styles.about_offer_content_inner2}>
                <img src='/dollar.png' alt='' />
                <h4>Off-sites</h4>
                <p>
                  Are you ready for your summer holidays? Because we are,
                  all paid for.
                </p>
              </div>

              <div className={styles.about_offer_content_inner2}>
                <img src='/dollar.png' alt='' />
                <h4>And more...</h4>
                <p>
                  That’s not it, we’ve got awhole host more benefits for when
                  you join.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.about_crew_wrapper}>
          <div>
            <h3>Meet The Crew</h3>
            <p>
              A company is only as strong as its team members and we’ve got the
              best we’re proud to show off
            </p>
          </div>
          <div className={styles.crew_member_wrapper}>
            {/* map crew */}
            {Crew.map((data) => (
              <>
                <div key={data.id} className={styles.crew_member_content}>
                  <div>
                    <img src={data.img} alt='' />
                  </div>
                  <div className={styles.crew_member_contents}>
                    <p className={styles.crew_member_position}>
                      <span>Position: </span>
                      {data.position}
                    </p>
                    <p className={styles.crew_member_name}>
                      <span>Name: </span> {data.name}
                    </p>
                    <p className={styles.crew_member_email}>
                      <span>Email Address: </span> {data.email}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>

        {/* MOBILE */}
        <div className={styles.about_mobile_testimonial_wrapper}>
          <div className={styles.about_mobile_testimonial_header}>
            <h3>Testimonials</h3>
            <p>See what people are saying about us</p>
          </div>
          <Carousel>
            <div className={styles.card}>
              <div className={styles.card_inner}>
                <img src='/authorimg.png' alt='' />
                <h5>Name</h5>
                <h6>company Name</h6>
                <p>
                  “Collect customer reviews with automatic requests, and offer
                  discounts for including photos or videos.
                </p>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.card_inner}>
                <img src='/authorimg.png' alt='' />
                <h5>Name</h5>
                <h6>company Name</h6>
                <p>
                  “Collect customer reviews with automatic requests, and offer
                  discounts for including photos or videos.
                </p>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.card_inner}>
                <img src='/authorimg.png' alt='' />
                <h5>Name</h5>
                <h6>company Name</h6>
                <p>
                  “Collect customer reviews with automatic requests, and offer
                  discounts for including photos or videos.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
        {/* DESKTOP */}
        <div className={styles.testimonial_wrapper}>
          <div className={styles.testimonial_header}>
            <h3>Testimonials</h3>
            <p>See what people are saying about us</p>
          </div>
          <div className={styles.testimonial_card_wrapper}>
            <motion.div className={styles.testimonial_carousel} ref={carousel}>
              <motion.div
                drag='x'
                dragConstraints={{ right: 0, left: -width }}
                className={styles.inner_carousel}>
                {Testimonial.map((data) => {
                  return (
                    <motion.div className={styles.testimonial_inner}>
                      <div className={styles.testimonial_inner_content}>
                        <div className={styles.testimonial_inner_card}>
                          <div className={styles.testimonial_user_profile}>
                            <img src={data.img} alt='' />
                            <p className={styles.testimonial_user_name}>
                              {data.Name}
                            </p>
                            <p className={styles.testimonial_user_companyName}>
                              {data.companyName}
                            </p>
                          </div>

                          <p className={styles.testimonial_text}>
                            {data.content}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
              </motion.div>
            </motion.div>
          </div>
        </div>

        <div className={styles.about_subfooter_wrapper}>
          <div className={styles.about_subfooter_content}>
            <p>Have a dream you want to get fulfilled?</p>
            <h3>Join us</h3>
            <br />
            <br />
            <br />
          </div>
          <p>
            Visit our <span>careers page</span> for opportunities
          </p>
          <p>
            Send us message on our <span>contact page</span>
          </p>
        </div>
      </div>
    </Layout>
  );
}
